<template>
  <UiAlert type="primary">
    <i
      class="fa fa-exclamation-triangle"
      aria-hidden="true"
    />
    <!-- eslint-disable-next-line -->
    {{ $t('We do not support Internet Explorer. Please switch to a modern browser (e.g. Chrome, Firefox, Safari or Edge) for more security, speed and the best experience.') }}
  </UiAlert>
</template>
<script>
import { UiAlert } from '@sendcloud/ui-library'
export default {
  name: 'InternetExplorerSupport',
  components: {
    UiAlert,
  },
}
</script>
